<template lang="pug">
.form(:class="{'form-send': sendForm}")
  .form__block.form__block-form
    h2.center(v-html="formTitle")

    .form__fields
      .input-text(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )

      .input-text(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="text"
          v-model="fieldTopic"
          :placeholder="formFieldTopicPlaceholder"
        )

      .input-text
        input.input-text__input(
          type="email"
          v-model="fieldEmail"
          :placeholder="formFieldEmailPlaceholder"
        )

      .input-text(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form__text
      .input-textarea
        textarea.input-textarea__input(
          v-model="fieldText"
          :placeholder="formFieldTextPlaceholder"
        )

    .form__file
      .form-files
        .form-files__list
          .form-files__item
            input(
              type="file"
              name="files"
              ref="files"
              id="files"
              multiple
              style="display: none;"
              @change="onFileChange"
            )
            label.form-files__label(
              v-html="formFieldFilesButton"
              for="files"
            )
          .form-files__item
            label.form-files__result(v-html="fileResult")

        .form-files__description(v-html="formFieldFilesText")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button
      .button.button-green(
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")

  .form__success
    h2.center(v-html="formSuccessTitle")
    p(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formType",

    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldTopic",
    "formFieldTopicPlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    'formFieldFilesButton',
    'formFieldFilesResult',
    'formFieldFilesText',

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldTopic: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      fileResult: this.formFieldFilesResult,
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        if (this.formType === 'form') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldTopic,
                value: this.fieldTopic
              },
              {
                title: this.formFieldEmail,
                value: this.fieldEmail
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldText,
                value: this.fieldText
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldTopic = this.fieldEmail = this.fieldPhone = this.fieldText = this.files = ''
          this.fileResult = this.formFieldFilesResult
        }

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      fieldTopic: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
