/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Gallery from '@/assets/js/Gallery/Gallery'
import Map from '@/assets/js/Map/Map'
import Header from '@/assets/js/Header/Header'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import AppForm from '@/components/Form/AppForm.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new Gallery()
    new Map()
    new Header()
    new ButtonTop()

    if(document.getElementById('block--form-popup') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }
})
