/*global $*/
/*eslint no-undef: "error"*/
export default class Gallery {
    classItem = 'gallery--item'
    classTab = 'gallery--tab'
    classBlock = 'gallery--block'

    classTabActive = 'button-coffee'
    classTabNoActive = 'button-gray'
    classBlockActive = 'gallery-tabs__block-active'

    constructor() {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classTab}`).removeClass(self.classTabActive).addClass(self.classTabNoActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classTab}[data-block='${block}']`).addClass(self.classTabActive).removeClass(self.classTabNoActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
